import * as tslib_1 from "tslib";
import { ItemsService } from './../../items.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { of, Observable } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom, flatMap } from 'rxjs/operators';
import { Item, ItemBrand, ItemDetail, ItemDetailType, ItemImage, Supplier, SupplierItem, SupplierItemInput, ItemInline, valueLabel } from './../../models';
import * as fromActions from './items.action';
import * as fromSelectors from './items.selector';
var ItemsEffects = /** @class */ (function () {
    function ItemsEffects(actions$, store, itemsService, router, route) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.itemsService = itemsService;
        this.router = router;
        this.route = route;
        this.loadItems$ = this.actions$.pipe(ofType(fromActions.LOAD_ITEMS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('effect');
            return _this.itemsService.loadItems(action['payload'].filter).pipe(map(function (items) {
                // console.log('items from effect', items)
                return new fromActions.LoadItemsSuccess({ items: items, filter: action['payload'].filter });
            }), catchError(function (error) {
                // console.log('error', error);
                return of(new fromActions.LoadItemsFail({ error: error }));
            }));
        }));
        this.loadSuggestedImages$ = this.actions$.pipe(ofType(fromActions.LOAD_SUGGESTED_IMAGES), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadItems({ brand_id: action['payload'].brandId }).pipe(map(function (items) {
                var itemImages = [];
                items.forEach(function (item) {
                    itemImages.push.apply(itemImages, item.itemImages);
                });
                return new fromActions.LoadSuggestedImagesSuccess({ itemImages: itemImages });
            }), catchError(function (error) { return of(new fromActions.LoadSuggestedImagesFail({ error: error })); }));
        }));
        this.loadHighLevelTypes$ = this.actions$.pipe(ofType(fromActions.LOAD_HIGH_LEVEL_TYPES), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadHighLevelTypes().pipe(map(function (highLevelTypes) { return new fromActions.LoadHighLevelTypesSuccess({ highLevelTypes: highLevelTypes }); }), catchError(function (error) { return of(new fromActions.LoadHighLevelTypesFail({ error: error })); }));
        }));
        this.loadItemDetailTypes$ = this.actions$.pipe(ofType(fromActions.LOAD_ITEM_DETAIL_TYPES), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadItemDetailTypes().pipe(map(function (itemDetailTypes) { return new fromActions.LoadItemDetailTypesSuccess({ itemDetailTypes: itemDetailTypes }); }), catchError(function (error) { return of(new fromActions.LoadItemDetailTypesFail({ error: error })); }));
        }));
        this.loadItem$ = this.actions$.pipe(ofType(fromActions.LOAD_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadItem(action['payload'].id).pipe(flatMap(function (item) {
                return [
                    new fromActions.LoadItemSuccess({ item: item }),
                    new fromActions.LoadBrands(),
                    // new fromActions.LoadLabels(),
                    new fromActions.LoadHighLevelTypes(),
                    new fromActions.LoadCountries()
                ];
            })
            // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
            , catchError(function (error) {
                // console.log('error', error);
                return of(new fromActions.LoadItemFail({ error: error }));
            }));
        }));
        this.loadMixedCase$ = this.actions$.pipe(ofType(fromActions.LOAD_MIXED_CASE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadItem(action['payload'].id).pipe(flatMap(function (item) {
                return [
                    new fromActions.LoadMixedCaseSuccess({ item: item }),
                    new fromActions.LoadBrands(),
                    // new fromActions.LoadLabels(),
                    new fromActions.LoadHighLevelTypes(),
                    new fromActions.LoadCountries()
                ];
            })
            // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
            , catchError(function (error) {
                // console.log('error', error);
                return of(new fromActions.LoadItemFail({ error: error }));
            }));
        }));
        this.loadItemMixed$ = this.actions$.pipe(ofType(fromActions.LOAD_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('item mixed')
            return _this.itemsService.loadItemMixed(action['payload'].id, action['payload'].item_id).pipe(flatMap(function (item) {
                return [
                    new fromActions.LoadMixedItemSuccess({ item: item }),
                    new fromActions.LoadBrands(),
                    // new fromActions.LoadLabels(),
                    new fromActions.LoadHighLevelTypes(),
                    new fromActions.LoadCountries()
                ];
            })
            // catchError((error: any) => {of(new fromActions.LoadItemFail({ error })))}
            , catchError(function (error) {
                // console.log('error', error);
                return of(new fromActions.LoadItemFail({ error: error }));
            }));
        }));
        // duplicate item
        this.duplicateItem$ = this.actions$.pipe(ofType(fromActions.DUPLICATE_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.duplicateItem(action['payload']._id).pipe(flatMap(function (item) {
                if (item && item.mixed_case === true) {
                    _this.router.navigate(["catalogue/mixed-cases/edit/2/" + item._id]);
                }
                else {
                    _this.router.navigate(["catalogue/items/edit/2/" + item._id]);
                }
                return [
                    new fromActions.DuplicateItemSuccess({ item: item })
                ];
            }), catchError(function (error) { return of(new fromActions.DuplicateItemFail({ error: error })); }));
        }));
        // duplicate item
        // duplicate item
        this.duplicateMixedItem$ = this.actions$.pipe(ofType(fromActions.DUPLICATE_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.duplicateMixedItem(action['payload'].clone_item_id, action['payload'].item_id).pipe(flatMap(function (item) {
                var mixedCase = state.currentMixedCase;
                return [
                    new fromActions.LoadMixedCase({ id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.DuplicateMixedItemFail({ error: error })); }));
        }));
        // duplicate item
        // duplicate item
        this.duplicateItemMixedItem$ = this.actions$.pipe(ofType(fromActions.DUPLICATE_ITEM_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.duplicateItemMixedItem(action['payload'].clone_item_id, action['payload'].item_id).pipe(flatMap(function (item) {
                var mixedCase = state.currentMixedCase;
                return [
                    new fromActions.LoadMixedCase({ id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.DuplicateMixedItemFail({ error: error })); }));
        }));
        // duplicate item
        this.createItem$ = this.actions$.pipe(ofType(fromActions.CREATE_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.createItem(action['payload'].item).pipe(flatMap(function (item) {
                if (item && item.mixed_case === true) {
                    _this.router.navigate(["catalogue/mixed-cases/edit/2/" + item._id]);
                }
                else {
                    _this.router.navigate(["catalogue/items/edit/2/" + item._id]);
                }
                return [
                    new fromActions.CreateItemSuccess({ item: item })
                ];
            }), catchError(function (error) { return of(new fromActions.CreateItemFail({ error: error })); }));
        }));
        this.createMixedItem$ = this.actions$.pipe(ofType(fromActions.CREATE_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.createMixedItem(action['payload'].item).pipe(flatMap(function (item) {
                // console.log(item)
                var mixedCase = state.currentMixedCase;
                return [
                    new fromActions.CreateMixedItemSuccess({ item: item }),
                    new fromActions.LoadMixedCase({ id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.CreateItemFail({ error: error })); }));
        }));
        // let mixedCase: Item = state.currentMixedCase
        // if (mixedCase) {
        //   return new fromActions.LoadMixedCase({ id: mixedCase._id })
        // }
        this.createItemDetail$ = this.actions$.pipe(ofType(fromActions.CREATE_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('createItemDetail')
            return _this.itemsService.createItemDetail(action['payload'].itemDetail).pipe(flatMap(function (itemDetail) {
                // console.log(itemDetail)
                var mixedCase = state.currentMixedCase;
                if (mixedCase) {
                    return [
                        new fromActions.LoadMixedCase({ id: mixedCase._id })
                    ];
                }
                else {
                    return [
                        new fromActions.CreateItemDetailSuccess({ itemDetail: itemDetail }),
                    ];
                }
            }), catchError(function (error) { return of(new fromActions.CreateItemDetailFail({ error: error })); }));
        }));
        //       map((itemDetail: ItemDetail) => new fromActions.CreateItemDetailSuccess({ itemDetail })),
        //       catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
        //     );
        //   })
        // );
        this.createMixedItemDetail$ = this.actions$.pipe(ofType(fromActions.CREATE_MIXED_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('xxx createMixedItemDetail', state)
            var mixedCase = state.currentMixedCase;
            return _this.itemsService.createMixedItemDetail(action['payload'].itemDetail).pipe(flatMap(function (itemDetail) {
                // console.log('createMixedItemDetail', action['payload'])
                // console.log('createMixedItemDetail state', state.currentMixedCase)
                // return new fromActions.UpdateMixedItemSuccess({ item });
                var mixedCase = state.currentMixedCase;
                var mixedItem = state.currentMixedItem;
                return [
                    new fromActions.UpdateMixedItemDetailSuccess({ itemDetail: itemDetail }),
                    new fromActions.LoadMixedCase({ id: mixedCase._id }),
                    new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.CreateItemDetailFail({ error: error })); })
            // map((itemDetail: ItemDetail) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
            // catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
            );
        }));
        this.updateItemDetail$ = this.actions$.pipe(ofType(fromActions.UPDATE_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.updateItemDetail(action['payload'].itemDetail).pipe(flatMap(function (itemDetail) {
                // console.log(itemDetail)
                var mixedCase = state.currentMixedCase;
                if (mixedCase) {
                    return [
                        new fromActions.LoadMixedCase({ id: mixedCase._id })
                    ];
                }
                else {
                    return [
                        new fromActions.UpdateItemDetailSuccess({ itemDetail: itemDetail }),
                    ];
                }
            }), catchError(function (error) { return of(new fromActions.UpdateItemDetailFail({ error: error })); }));
        }));
        //     return this.itemsService.updateItemDetail(action['payload'].itemDetail).pipe(
        //       map((itemDetail: ItemDetail) => new fromActions.UpdateItemDetailSuccess({ itemDetail })),
        //       catchError((error: any) => of(new fromActions.UpdateItemDetailFail({ error })))
        //     );
        //   })
        // );
        this.updateMixedItemDetail$ = this.actions$.pipe(ofType(fromActions.UPDATE_MIXED_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log(action['payload'])
            return _this.itemsService.updateMixedItemDetail(action['payload'].itemDetail).pipe(flatMap(function (itemDetail) {
                // console.log('updateMixedItemDetail', action['payload'])
                // console.log('updateMixedItemDetail state', state.currentMixedCase)
                // return new fromActions.UpdateMixedItemSuccess({ item });
                var mixedCase = state.currentMixedCase;
                var mixedItem = state.currentMixedItem;
                return [
                    new fromActions.UpdateMixedItemDetailSuccess({ itemDetail: itemDetail }),
                    new fromActions.LoadMixedCase({ id: mixedCase._id }),
                    new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.UpdateItemDetailFail({ error: error })); }));
        }));
        this.updateMixedItem$ = this.actions$.pipe(ofType(fromActions.UPDATE_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log(action['payload'])
            return _this.itemsService.updateMixedItem(action['payload'].item).pipe(flatMap(function (item) {
                // console.log('updateMixedItem', action['payload'])
                // console.log('updateMixedItem state', state.currentMixedCase)
                // return new fromActions.UpdateMixedItemSuccess({ item });
                var mixedCase = state.currentMixedCase;
                var mixedItem = state.currentMixedItem;
                return [
                    new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id }),
                    new fromActions.LoadMixedCase({ id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.UpdateItemFail({ error: error })); }));
        }));
        this.deleteItemDetail$ = this.actions$.pipe(ofType(fromActions.DELETE_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('deleteItemDetail state', state.currentMixedCase)
            return _this.itemsService.deleteItemDetail(action['payload'].itemDetail).pipe(map(function (itemDetail) { return new fromActions.DeleteItemDetailSuccess({ itemDetail: itemDetail }); }), catchError(function (error) { return of(new fromActions.DeleteItemDetailFail({ error: error })); }));
        }));
        this.deleteMixedItemDetail$ = this.actions$.pipe(ofType(fromActions.DELETE_MIXED_ITEM_DETAIL), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('deleteItemDetail state', state.currentMixedCase)
            return _this.itemsService.deleteMixedItemDetail(action['payload'].itemDetail).pipe(flatMap(function (itemDetail) {
                // console.log('createMixedItemDetail', action['payload'])
                // console.log('createMixedItemDetail state', state.currentMixedCase)
                // return new fromActions.UpdateMixedItemSuccess({ item });
                var mixedCase = state.currentMixedCase;
                var mixedItem = state.currentMixedItem;
                return [
                    new fromActions.UpdateMixedItemDetailSuccess({ itemDetail: itemDetail }),
                    new fromActions.LoadMixedCase({ id: mixedCase._id }),
                    new fromActions.LoadMixedItem({ id: mixedItem._id, item_id: mixedCase._id })
                ];
            }), catchError(function (error) { return of(new fromActions.DeleteItemDetailFail({ error: error })); })
            // map((itemDetail: ItemDetail) => new fromActions.LoadMixedCase({ id: mixedCase._id })),
            // catchError((error: any) => of(new fromActions.CreateItemDetailFail({ error })))
            );
        }));
        this.updateItem$ = this.actions$.pipe(ofType(fromActions.UPDATE_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.updateItem(action['payload'].item).pipe(map(function (item) {
                // console.log('xxx updateItem', item)
                var mixedCase = state.currentMixedCase;
                if (mixedCase) {
                    return new fromActions.LoadMixedCase({ id: mixedCase._id });
                }
                return new fromActions.UpdateItemSuccess({ item: item });
            }), catchError(function (error) { return of(new fromActions.UpdateItemFail({ error: error })); }));
        }));
        this.deleteItem$ = this.actions$.pipe(ofType(fromActions.DELETE_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.deleteItem(action['payload']._id).pipe(map(function (item) { return new fromActions.DeleteItemSuccess({ item: item }); }), catchError(function (error) { return of(new fromActions.DeleteItemFail({ error: error })); }));
        }));
        this.deleteMixedItem$ = this.actions$.pipe(ofType(fromActions.DELETE_MIXED_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            var mixedCase = state.currentMixedCase;
            return _this.itemsService.deleteMixedItem(action['payload']._id, action['payload'].item_id).pipe(map(function (item) { return new fromActions.LoadMixedCase({ id: mixedCase._id }); }), catchError(function (error) { return of(new fromActions.DeleteItemFail({ error: error })); }));
        }));
        this.deleteItemImage$ = this.actions$.pipe(ofType(fromActions.DELETE_ITEM_IMAGE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('delete')
            return _this.itemsService.deleteItemImage(action['payload']).pipe(map(function (item) { return new fromActions.DeleteItemImageSuccess(tslib_1.__assign({}, action['payload'])); }), catchError(function (error) { return of(new fromActions.DeleteItemImageFail({ error: error })); }));
        }));
        this.deleteItemMixedCaseImage$ = this.actions$.pipe(ofType(fromActions.DELETE_ITEM_MIXED_CASE_IMAGE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('delete')
            var mixedCase = state.currentMixedCase;
            return _this.itemsService.deleteItemImage(action['payload']).pipe(map(function (item) { return new fromActions.LoadMixedCase({ id: mixedCase._id }); }), catchError(function (error) { return of(new fromActions.DeleteItemImageFail({ error: error })); }));
        }));
        this.deleteItemMixedImage$ = this.actions$.pipe(ofType(fromActions.DELETE_ITEM_MIXED_IMAGE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('delete')
            var mixedCase = state.currentMixedCase;
            var mixedCaseItem = state.currentMixedItem;
            return _this.itemsService.deleteItemMixedImage(action['payload']).pipe(map(function (item) { return new fromActions.LoadMixedItem({ id: mixedCaseItem._id, item_id: mixedCase._id }); }), catchError(function (error) { return of(new fromActions.DeleteItemImageFail({ error: error })); }));
        }));
        this.uploadItemImage$ = this.actions$.pipe(ofType(fromActions.UPLOAD_ITEM_IMAGE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.uploadItemImage(action['payload']).pipe(map(function (itemImage) { return new fromActions.UploadItemImageSuccess(itemImage); }), catchError(function (error) { return of(new fromActions.UploadItemImageFail({ error: error })); }));
        }));
        this.uploadItemMixedImage$ = this.actions$.pipe(ofType(fromActions.UPLOAD_ITEM_MIXED_IMAGE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('blegh', state)
            var mixedCase = state.currentMixedCase;
            var mixedCaseItem = state.currentMixedItem;
            return _this.itemsService.uploadItemMixedImage(action['payload']).pipe(map(function (itemImage) { return new fromActions.LoadMixedItem({ id: mixedCaseItem._id, item_id: mixedCase._id }); }), catchError(function (error) { return of(new fromActions.UploadItemImageFail({ error: error })); }));
        }));
        this.uploadItemImageListing$ = this.actions$.pipe(ofType(fromActions.UPLOAD_ITEM_IMAGE_LISTING), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('blegh')
            return _this.itemsService.uploadItemImageListing(action['payload']).pipe(map(function (itemImage) { return new fromActions.UploadItemMixedItemImageSuccess(itemImage); }), catchError(function (error) { return of(new fromActions.UploadItemImageFail({ error: error })); }));
        }));
        this.searchItems$ = this.actions$.pipe(ofType(fromActions.SEARCH_ITEMS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.searchItems(action['payload'].query, action['payload'].limit, action['payload'].filters).pipe(map(function (items) { return new fromActions.SearchItemsSuccess({ items: items }); }), catchError(function (error) { return of(new fromActions.SearchItemsFail({ error: error })); }));
        }));
        ///
        ///
        this.loadBrands$ = this.actions$.pipe(ofType(fromActions.LOAD_BRANDS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('xxx bRANDS')
            return _this.itemsService.loadBrands().pipe(map(function (brands) { return new fromActions.LoadBrandsSuccess({ brands: brands }); }), catchError(function (error) { return of(new fromActions.LoadBrandsFail({ error: error })); }));
        }));
        this.loadCountries$ = this.actions$.pipe(ofType(fromActions.LOAD_COUNTRIES), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadCountries().pipe(map(function (countries) { return new fromActions.LoadCountriesSuccess({ countries: countries }); }), catchError(function (error) { return of(new fromActions.LoadCountriesFail({ error: error })); }));
        }));
        this.loadSuppliers$ = this.actions$.pipe(ofType(fromActions.LOAD_SUPPLIERS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadSuppliers().pipe(map(function (suppliers) { return new fromActions.LoadSuppliersSuccess({ suppliers: suppliers }); }), catchError(function (error) { return of(new fromActions.LoadSuppliersFail({ error: error })); }));
        }));
        ///
        // delete supplier { _id: action['payload']._id, item_id: action['payload'].item_id }
        //
        this.deleteSupplier$ = this.actions$.pipe(ofType(fromActions.DELETE_SUPPLIER), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.deleteSupplier(action['payload']._id, action['payload'].item_id).pipe(map(function (supplierItem) { return new fromActions.DeleteSupplierSuccess({ supplierItem: supplierItem }); }), catchError(function (error) {
                // console.log('error', error);
                return of(new fromActions.DeleteSupplierFail({ error: error }));
            }));
        }));
        // delete supplier
        ///
        this.createSupplierItem$ = this.actions$.pipe(ofType(fromActions.CREATE_SUPPLIER_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.createSupplierItem(action['payload'].supplierItemInput).pipe(map(function (supplierItem) { return new fromActions.CreateSupplierItemSuccess({ supplierItem: supplierItem }); }), catchError(function (error) { return of(new fromActions.CreateSupplierItemFail({ error: error })); }));
        }));
        this.updateSupplierItem$ = this.actions$.pipe(ofType(fromActions.UPDATE_SUPPLIER_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.updateSupplierItem(action['payload'].supplierItemInput).pipe(map(function (supplierItem) {
                // console.log('xxx updateItem', supplierItem)
                var mixedCase;
                state && state.currentMixedCase ? mixedCase = state.currentMixedCase : mixedCase = false;
                // console.log('xxx updateItem', mixedCase)
                if (mixedCase) {
                    return new fromActions.LoadMixedCase({ id: mixedCase._id });
                }
                return new fromActions.UpdateSupplierItemSuccess({ supplierItem: supplierItem });
            }), catchError(function (error) { return of(new fromActions.UpdateSupplierItemFail({ error: error })); }));
        }));
        //       map((supplierItem: SupplierItem) => new fromActions.UpdateSupplierItemSuccess({ supplierItem })),
        //       catchError((error: any) => of(new fromActions.UpdateSupplierItemFail({ error })))
        //     );
        //   })
        // );
        this.loadMixedCaseSupplierItem$ = this.actions$.pipe(ofType(fromActions.LOAD_MIXED_CASE_SUPPLIER_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadSupplierItem(action['payload'].itemID).pipe(map(function (supplierItems) {
                var supplierItem = supplierItems.find(function (i) { return i.supplier_id === action['payload'].supplierID; });
                var supplierItemInput = {
                    item_id: action['payload'].itemID,
                    supplier_id: action['payload'].supplierID
                };
                // console.log('xxx supplierItems', action['payload'])
                // if (action['payload'].mixed_case) {
                //  new fromActions.DeleteSupplier({ _id: action['payload'].supplierID, item_id: action['payload'].itemID })
                // }
                // if (!supplierItem) {
                // return new fromActions.CreateSupplierItem({ supplierItemInput })
                // }
                return new fromActions.LoadMixedCaseSupplierItemSuccess({ supplierItem: supplierItem });
            }), catchError(function (error) { return of(new fromActions.LoadSupplierItemFail({ error: error })); }));
        }));
        this.loadSupplierItem$ = this.actions$.pipe(ofType(fromActions.LOAD_SUPPLIER_ITEM), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadSupplierItem(action['payload'].itemID).pipe(map(function (supplierItems) {
                var supplierItem = supplierItems.find(function (i) { return i.supplier_id === action['payload'].supplierID; });
                var supplierItemInput = {
                    item_id: action['payload'].itemID,
                    supplier_id: action['payload'].supplierID
                };
                // console.log('xxx supplierItems', action['payload'])
                // if (action['payload'].mixed_case) {
                //   new fromActions.DeleteSupplier({ _id: action['payload'].supplierID, item_id: action['payload'].itemID })
                // }
                if (!supplierItem) {
                    return new fromActions.CreateSupplierItem({ supplierItemInput: supplierItemInput });
                }
                return new fromActions.LoadSupplierItemSuccess({ supplierItem: supplierItem });
            }), catchError(function (error) { return of(new fromActions.LoadSupplierItemFail({ error: error })); }));
        }));
        this.loadSupplierItemConditionSuggest$ = this.actions$.pipe(ofType(fromActions.LOAD_SUPPLIER_ITEM_CONDITION_SUGGEST), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.loadSupplierItemConditionSuggest(action['payload'].condition).pipe(map(function (conditions) { return new fromActions.LoadSupplierItemConditionSuggestSuccess({ conditions: conditions }); }), catchError(function (error) { return of(new fromActions.LoadSupplierItemConditionSuggestFail({ error: error })); }));
        }));
        this.createBrand$ = this.actions$.pipe(ofType(fromActions.CREATE_BRAND), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.createBrand(action['payload'].brandInput).pipe(flatMap(function (brand) {
                return [
                    new fromActions.CreateBrandSuccess({ brand: brand }),
                    new fromActions.UpdateItem({
                        item: {
                            _id: state.currentItem._id,
                            brand_id: brand._id,
                            brandDescription: brand.name
                        }
                    })
                ];
            }), catchError(function (error) { return of(new fromActions.CreateBrandFail({ error: error })); }));
        }));
        this.updateBrand$ = this.actions$.pipe(ofType(fromActions.UPDATE_BRAND), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.updateBrand(action['payload'].id, action['payload'].brandInput).pipe(map(function (brand) { return new fromActions.UpdateBrandSuccess({ brand: brand }); }), catchError(function (error) { return of(new fromActions.UpdateBrandFail({ error: error })); }));
        }));
        this.getExcelTemplateUrl$ = this.actions$.pipe(ofType(fromActions.GET_EXCEL_TEMPLATE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.getExcelTemplate().pipe(map(function (excelTemplateUrl) { return new fromActions.GetExcelTemplateSuccess({ excelTemplateUrl: excelTemplateUrl }); }), catchError(function (error) { return of(new fromActions.GetExcelTemplateFail({ error: error })); }));
        }));
        this.importItems$ = this.actions$.pipe(ofType(fromActions.IMPORT_ITEMS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.importItems(action['payload'].itemsFile).pipe(map(function (status) { return new fromActions.ImportItemsSuccess({ status: status }); }), catchError(function (error) { return of(new fromActions.ImportItemsFail({ error: error })); }));
        }));
        this.exportItems$ = this.actions$.pipe(ofType(fromActions.EXPORT_ITEMS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.exportItems(action['payload'].filetype).pipe(map(function (res) {
                var downloadURL = window.URL.createObjectURL(res);
                var link = document.createElement('a');
                link.href = downloadURL;
                action['payload'].filetype === 'pdf' ? link.download = 'Stories.pdf' : link.download = 'Stories.csv';
                link.click();
                window.URL.revokeObjectURL(downloadURL);
                return new fromActions.ExportItemsSuccess();
            }), catchError(function (error) { return of(new fromActions.ExportItemsFail({ error: error })); }));
        }));
        this.createItemReview$ = this.actions$.pipe(ofType(fromActions.CREATE_ITEM_REVIEW), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.createItemReview(action['payload'].item_id, action['payload'].text).pipe(map(function (itemReview) {
                return new fromActions.CreateItemReviewSuccess({ itemReview: itemReview });
            }), catchError(function (error) { return of(new fromActions.CreateItemReviewFail({ error: error })); }));
        }));
        this.updateItemInline$ = this.actions$.pipe(ofType(fromActions.UPDATE_ITEM_INLINE), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), switchMap(function (_a) {
            var action = _a[0], state = _a[1];
            return _this.itemsService.updateItemInline(action['payload'].item).pipe(map(function (item) {
                var updateItem = item.editItem;
                var updateSupItem = item.editSupplierItem;
                // console.log('updateItem', updateItem)
                // console.log('updateSupItem', updateSupItem)
                return new fromActions.UpdateItemInlineSuccess({ item: updateItem, supplierItemInput: updateSupItem });
                // return [
                //   new fromActions.UpdateItemSuccess({ item: updateItem }),
                //   new fromActions.UpdateSupplierItemSuccess({ supplierItem: updateSupItem })
                // ];
            }), catchError(function (error) {
                // console.log('UpdateItemInlineFail', error);
                return of(new fromActions.UpdateItemInlineFail({ error: error }));
            }));
        }));
        ///
        this.loadLabels$ = this.actions$.pipe(ofType(fromActions.LOAD_LABELS), withLatestFrom(this.store.pipe(select(fromSelectors.getItemsState))), flatMap(function (_a) {
            var action = _a[0], state = _a[1];
            // console.log('xxx', state);
            return _this.itemsService.loadLabels1().pipe(map(function (labels) { return new fromActions.LoadLabelsSuccess({ labels: labels }); }), catchError(function (error) { return of(new fromActions.LoadLabelsFail({ error: error })); }));
        }));
    }
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadItems$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadSuggestedImages$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadHighLevelTypes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadItemDetailTypes$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadMixedCase$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadItemMixed$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "duplicateItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "duplicateMixedItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "duplicateItemMixedItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createMixedItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createMixedItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateMixedItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateMixedItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteMixedItemDetail$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteMixedItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteItemImage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteItemMixedCaseImage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteItemMixedImage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "uploadItemImage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "uploadItemMixedImage$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "uploadItemImageListing$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "searchItems$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadBrands$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadCountries$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadSuppliers$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "deleteSupplier$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createSupplierItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateSupplierItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadMixedCaseSupplierItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadSupplierItem$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadSupplierItemConditionSuggest$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createBrand$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateBrand$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "getExcelTemplateUrl$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "importItems$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "exportItems$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "createItemReview$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "updateItemInline$", void 0);
    tslib_1.__decorate([
        Effect(),
        tslib_1.__metadata("design:type", Observable)
    ], ItemsEffects.prototype, "loadLabels$", void 0);
    return ItemsEffects;
}());
export { ItemsEffects };
